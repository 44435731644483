
// import React from "react";
// import Layout from '../components/layout'
// import Gallery from '../components/gallery'

// const Photos = () => {
//     return (
//         <Layout>
//             <Gallery/>
//         </Layout>
//     )
// }

// export default Photos


import React from 'react'

// components
import Header from '../components/header'
import Footer from '../components/footer'
import Gallery from '../components/gallery'

import "../styles/photos.scss"


const Photos = (props) => {
    return (
        <div class="container">
            <div class="content">
                <Header />
                <div class="gallery">
                    <Gallery />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Photos