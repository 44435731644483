import React from 'react'
import { OutboundLink } from "gatsby-plugin-google-gtag"

import * as footerStyles from './footer.module.scss'

const Footer = () => {
    return (
        <footer className={footerStyles.text}>
            <OutboundLink  href="mailto:me@matthewsumpter.org">
               Email 
            </OutboundLink>
            {' '}
            <OutboundLink  href="https://keybase.io/mjsumpter">
                Keybase
            </OutboundLink>
            <p>Built with <OutboundLink className={footerStyles.link} href='http://www.gatsbyjs.org' target="_blank" rel="noopener noreferrer">GatsbyJS</OutboundLink>, &copy; 2022</p>
        </footer>
    )
}

export default Footer