import React from 'react'
import { Link } from 'gatsby'
import { OutboundLink } from "gatsby-plugin-google-gtag"

import * as headerStyles from './header.module.scss'
import profileMain from '../imgs/profile_main.jpeg'
import github from '../imgs/github.png'
import linked from '../imgs/linked.png'
import email from '../imgs/email.png'
import home from '../imgs/home.png'
import cv from '../../static/Sumpter_CV.pdf'


let profileStyle = {
    
    background: `url(${profileMain})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    borderRadius: "50%",
    height: "200px",
    width: "200px",
    minWidth: "200px",
}

const Header = () => {
    return (
        <header className={headerStyles.container}>
            <nav className={headerStyles.navbar}>
                <div className={headerStyles.imgContainer} style={profileStyle} >
                    
                </div>
                <div className={headerStyles.imgOverlay}>
                    <Link className={headerStyles.home} to='/'>
                        <img src={home} alt="Home Icon" />
                    </Link>
                    <OutboundLink className={headerStyles.github} href="http://www.github.com/mjsumpter" target="_blank" rel="noopener noreferrer">
                        <img src={github} alt="Github" />
                    </OutboundLink>
                    <OutboundLink className={headerStyles.linked} href="http://www.linkedin.com/in/matthew-sumpter" target="_blank" rel="noopener noreferrer">
                        <img src={linked} alt="LinkedIn" />
                    </OutboundLink>
                    <OutboundLink className={headerStyles.email} href="mailto:me@matthewsumpter.org">
                        <img src={email} alt="Email Me" />
                    </OutboundLink>
                </div>
                <div className={headerStyles.iconSmall}>
                    <Link className={headerStyles.home} to='/'>
                        <img src={home} alt="Home Icon" />
                    </Link>
                    <OutboundLink className={headerStyles.github} href="http://www.github.com/mjsumpter" target="_blank" rel="noopener noreferrer">
                        <img src={github} alt="Github" />
                    </OutboundLink>
                    <OutboundLink className={headerStyles.linked} href="http://www.linkedin.com/in/matthew-sumpter" target="_blank" rel="noopener noreferrer">
                        <img src={linked} alt="LinkedIn" />
                    </OutboundLink>
                    <OutboundLink className={headerStyles.email} href="mailto:me@matthewsumpter.org">
                        <img src={email} alt="Email Me" />
                    </OutboundLink>
                </div>
                <ul className={headerStyles.list}>
                    <li className={headerStyles.mobileHome}>
                        <Link className={headerStyles.link} to='/' activeStyle={{ backgroundImage: "none" }}  >
                            Home
                        </Link>
                    </li>
                    <li className={headerStyles.listItem}>
                        <Link className={headerStyles.link} to='/blog' activeStyle={{ backgroundImage: "none" }}  >
                            Publications
                        </Link>
                    </li>
                    <li className={headerStyles.listItem}>
                        <Link className={headerStyles.link} to='/photos' activeStyle={{ backgroundImage: "none" }}>
                            Photography
                        </Link>
                    </li>
                    <li className={headerStyles.listItem}>
                        <Link className={headerStyles.link} to={cv} activeStyle={{ backgroundImage: "none" }}>
                            CV
                        </Link>
                    </li>
                </ul>
            </nav>
        </header>
    )
}

export default Header
