// extracted by mini-css-extract-plugin
export var container = "header-module--container--8b59b";
export var home = "header-module--home--40927";
export var github = "header-module--github--e06f8";
export var linked = "header-module--linked--a86f1";
export var email = "header-module--email--dd9ee";
export var imgOverlay = "header-module--imgOverlay--83471";
export var iconSmall = "header-module--iconSmall--592bd";
export var imgContainer = "header-module--imgContainer--06614";
export var navbar = "header-module--navbar--dc362";
export var img = "header-module--img--e2ef7";
export var mobileHome = "header-module--mobileHome--d0548";
export var list = "header-module--list--31b2e";
export var listItem = "header-module--listItem--26eea";
export var link = "header-module--link--8ce94";
export var linkActive = "header-module--linkActive--421b9";