
// import React, { useState, useCallback } from "react";
// import Gallery from "react-photo-gallery";
// import Carousel, { Modal, ModalGateway } from "react-images";
// import useGallery from '../hooks/useGallery'


// const GalleryComp = () => {

//     const photos = useGallery();
//     const [currentImage, setCurrentImage] = useState(0);
//     const [viewerIsOpen, setViewerIsOpen] = useState(false);

//     const openLightbox = useCallback((event, { photo, index }) => {
//         setCurrentImage(index);
//         setViewerIsOpen(true);
//     }, []);

//     const closeLightbox = () => {
//         setCurrentImage(0);
//         setViewerIsOpen(false);
//     };

//     return (
//         <div>
//             <Gallery photos={photos} onClick={openLightbox} />
//             <ModalGateway>
//                 {viewerIsOpen ? (
//                     <Modal onClose={closeLightbox}>
//                         <Carousel
//                             currentIndex={currentImage}
//                             views={photos.map(x => ({
//                                 ...x,
//                                 srcset: x.srcSet,
//                                 caption: x.title
//                             }))}
//                         />
//                     </Modal>
//                 ) : null}
//             </ModalGateway>
//         </div>
//     );
// }

// export default GalleryComp;



/** @jsx jsx */
import * as React from "react";
import { jsx } from "theme-ui";
import Img from "gatsby-image";
import { Lightbox } from "react-modal-image";
import useGallery from "../hooks/useGallery";
import Grid from "./Grid";
import Tile from "./Tile";

const imgStyles: any = {
    css: {
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        transition: "transform 0.5s, filter 0.25s",
        "&:hover": {
            transform: "scale(1.1)",
            filter: "saturate(1.3)",
        },
    },
};

const Gallery = () => {
    const images = useGallery();
    const [showImageIndex, setShowImageIndex] = React.useState<
        number | undefined
    >(undefined);

    return (
        <div>
            <Grid>
                {images.map((image, index) => (
                    <Tile
                        key={image.id}
                        onClick={() => {
                            setShowImageIndex(index);
                        }}
                    >
                        <Img alt={image.name} fluid={image.fluid} {...imgStyles} />
                    </Tile>
                ))}
            </Grid>
            {showImageIndex !== undefined && (
                <Lightbox
                    hideDownload={true}
                    large={images[showImageIndex].publicURL}
                    onClose={() => {
                        setShowImageIndex(undefined);
                    }}
                />
            )}
        </div>
    );
};

export default Gallery;